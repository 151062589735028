import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import PieChart from '../components/PieChart';
import Header from "../components/Header";
import LoadingSpinner from '../components/LoadingSpinner';

import DataManager from '../util/DataManager';
import { log } from '../util/Utils';
import Globals from '../Globals';


const dataManager = new DataManager();

const logg = (message) => {
    log("ListDayAppointments", message);
}

const ListDayAppointments = () => {
    const [loading, setLoading] = useState(false);
    const [loadingText] = useState("Termine werden geladen")
    const [data, setData] = useState([]);
    const [headerText, setHeaderText] = useState("");
    const loc = useLocation();
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    
    useEffect(() => {
        if (loc.state) {
            if (loc.state.permid) {
                console.log("Permid: ", JSON.stringify(loc.state.permid));
                retrieveAppointmentData(loc.state.permid);
            }
            if (loc.state.title) {
                setHeaderText(loc.state.title);
            }
        }

        window.addEventListener("resize", handleResize);

    }, [])

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    const retrieveAppointmentData = async (permid) => {
        setLoading(true);

        let sessionRenderArr = sessionStorage.getItem(Globals.SESSION_STORAGE_RENDER_APPOINTMENT+permid);
        if (loc.state.session && sessionRenderArr) {
            logg("Found session storage data for appointments");
            setLoading(false);
            setData(JSON.parse(sessionRenderArr));
            return;
        }else {
            logg("No session storage data for appointments");
        }

        let listData = await dataManager.getSavedLists();

        if (!listData) {
            return [];
        }

        listData = listData.find(list => list.permid === permid);

        let userData = await dataManager.getSavedUsers(permid);

        if (!userData || !userData.data) {
            return [];
        }

        //The acutal userdata array
        userData = userData.data;

        let appointments = listData.roomAppointments;
        let pieChartArr = [];

        //appointments = appointments.slice(0, 2);

        appointments.forEach((appoint) => {
            let appointRender = dataManager.getAppointmentData(appoint, userData);
            if (appointRender) {
                pieChartArr.push(appointRender);
            }
        });

        setLoading(false);
        setData(pieChartArr);

        sessionStorage.setItem(Globals.SESSION_STORAGE_RENDER_APPOINTMENT+permid, JSON.stringify(pieChartArr));
    }

    return(
        <div className="App">
            <Header/>
            <ListText>{headerText}</ListText>
            <MainContainer>
                {loading ? <LoadingSpinner loadingText={loadingText} /> : null }
                <ChartContainer resolution={windowSize}>
                    {data.map((list, index) => {
                        return <PieChart
                            data={list}
                            key={index}
                            onlyTwo={true}
                        />
                    })}
                </ChartContainer>
            </MainContainer>
        </div>
    );
}

const ListText = styled.div({
    alignSelf: 'center',
    marginTop: 10,
    fontSize: 40,
    fontWeight: 100,
});

const MainContainer = styled.div({
    display: 'flex',
    height: '100vh',
    width: '100vw',
    justifyContent: 'center',
});

const ChartContainer =styled.div(
    props => ({
        display: 'grid',
        gridTemplateColumns: props.resolution.width > Globals.GRID_4_COL_WIDTH ? 'auto auto auto auto' : props.resolution.width > Globals.GRID_3_COL_WIDTH ? 'auto auto auto' : 'auto auto',
        padding: 10,
        overflowY: 'scroll'
    })
);


export default ListDayAppointments;